import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../components/i18n";

import { NavLink } from "react-router-dom";
// import video from "../../assets/images/intro_video.mov";

import sponsor_1 from "../../assets/images/rec_casa_logo_200.png";
import sponsor_2 from "../../assets/images/rec_riis_logo.png";
import sponsor_3 from "../../assets/images/rec_blume_logo_2.jpeg";
import sponsor_4 from "../../assets/images/rec_bkb.png";
import sponsor_5 from "../../assets/images/rec_primeo_energie_rgb_72dpi.jpg";

import Sponsors from "../../components/Sponsors";
import News from "../../components/News";

export default function Home() {
  const { t } = useTranslation();

  const homeImageStyle = {
    width: "100%", // Wrap 100% in quotes
    maxHeight: "500px", // Use camelCase for max-height
  };
  const handleFooterNavClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    };

    if (checkIfMobile()) {
      setIsMobile(true);
    }
  }, []);

  const getIframeSrc = () => {
    const baseSrc =
      "https://www.youtube.com/embed/2-davaRj5XA?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=2-davaRj5XA&mute=1";
    return isMobile ? baseSrc.replace("mute=1", "mute=0") : baseSrc;
  };

  // const getIframeSrc = () => {
  //   const baseSrc =
  //     "https://www.youtube.com/embed/2-davaRj5XA?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&loop=1&playlist=2-davaRj5XA";

  //   // Check if it's mobile or not
  //   // const isMobile = /* your mobile detection logic */;

  //   // Always autoplay and mute unless it's explicitly mobile
  //   const autoplayAndMute = !isMobile
  //     ? "&autoplay=1&mute=1"
  //     : "&autoplay=0&mute=0";

  //   // Construct the final src
  //   const src = baseSrc + autoplayAndMute;

  //   console.log(isMobile, autoplayAndMute);
  //   console.log("Final video src:", src);
  //   return src;
  // };

  return (
    <div className="container p-0 my-0 mt-3 pageClass px-3">
      {/* <div className="alert alert-warning alert-dismissible fade show rounded-0 bg-index-alert mt-3">
        {t("home_banner_1")}
      </div> */}

      <div className="container mt-2 mb-1">
        {/* <video
          className="video"
          playsInline
          autoPlay
          muted
          loop
          controlsList="nodownload"
        >
          <source src={video} type="video/mp4" />
        </video> */}

        <div className="video-container">
          <iframe
            id="youtube-video"
            src={getIframeSrc()}
            allow="autoplay; encrypted-media"
            allowFullScreen
            style={{ pointerEvents: isMobile ? "auto" : "none" }}
          ></iframe>
          {/* <iframe
            className="ytvid"
            src="https://www.youtube.com/embed/2-davaRj5XA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=2-davaRj5XA"
          ></iframe> */}
        </div>
      </div>

      <div className="container mt-2 mb-5">
        <div className="row justify-content-lg-center">
          <div className="col-12 col-lg-12 px-0">
            <div className="overflow-hidden text-center">
              <h4 className="pt-1 pt-xl-1 my-3 px-3 home-intro">
                {t("home_intro")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row justify-content-lg-center">
          <div className="d-flex flex-column align-items-center">
            <Sponsors />
            <p className="mt-2">
              {t("home_sponsor_1")}
              <NavLink
                to="/sponsor"
                className="p-0"
                onClick={handleFooterNavClick}
              >
                {t("home_sponsor_2")}
              </NavLink>
              .
            </p>
          </div>
        </div>

        <div className="row justify-content-lg-center">
          <div className="d-flex flex-column align-items-center">
            <News />
            
          </div>
        </div>
      </div>
    </div>
  );
}
