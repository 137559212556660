import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import HomePage from "./pages/home/HomePage";
import ContactPage from "./pages/contact/ContactPage";
import TestimonialPage from "./pages/testimonial/TestimonialPage";
import TestimonialSubmitPage from "./pages/testimonial/TestimonialSubmitPage";
import AboutPage from "./pages/about/AboutPage";
import CoursesPage from "./pages/courses/CoursesPage";
import BookingPage from "./pages/booking/BookingPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";

import FAQPage from "./pages/faq/FAQPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFoundPage from "./pages/notfound/NotFoundPage";
import SitemapPage from "./pages/sitemap/SitemapPage";
import SponsorPage from "./pages/sponsor/SponsorPage";

import "./assets/styles/light/App.scss";

export default function App() {
  return (
    <BrowserRouter>
      <div className="pt-3 pb-0">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/sitemap" element={<SitemapPage />} />

          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route
            path="/testimonial/submit"
            element={<TestimonialSubmitPage />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/sponsor" element={<SponsorPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/courses" element={<CoursesPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
