import { useTranslation } from "react-i18next";
import "../../components/i18n";
import { NavLink } from "react-router-dom";

export default function TestimonialPage() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-5 pageClass px-3">
      <div className="card testimonial-prompt border rounded-0 shadow-sm">
        <div className="card-body">
          <h5 className="card-title">{t("submit_testimonial_1")}</h5>
          <p className="card-text">{t("submit_testimonial_2")} </p>

          <NavLink className="btn btn-md rounded-5 btn-custom-1" to="./submit">
            {t("footer_testimonial")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
